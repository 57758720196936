<template>
  <div class="the-header">
    <div class="begin pb-8 flex items-center justify-between">
      <div class="part-1 flex items-center">
        <div class="title text-2xl font-semibold mr-3">
          <span>{{ currentRoute + ' ' }}</span>
          <span v-if="showNumber">{{ '(' + numbers + ')' }}</span>
        </div>
        <div v-if="showSearch" class="search-bloc mx-3">
          <div class="searching relative">
            <div class="absolute flex border border-transparent left-0 top-0 h-full w-12">
              <div class="icon flex items-center justify-center rounded-tl-xl rounded-bl-xl z-10 h-full w-full">
                <icon :data="icons.searching" original width="15" height="15"/>
              </div>
            </div>
            <input
              v-model="search"
              type="text"
              placeholder="Search"
              class="ipt relative w-full border rounded-xl h-12 bg-white focus:outline-none py-4 pr-4 pl-12"
              @keyup="searchForItems"
            >
          </div>
        </div>
        <!-- NOTIFICATIONS BLOC -->
        <div class="notifications mx-3 relative">
          <div class="cursor-pointer" @click="openNotification">
            <icon v-if="numberNotification > 0" :data="icons.notifications" original width="20" height="20"/>
            <icon v-if="numberNotification === 0" :data="icons.notification" original width="20" height="20"/>
          </div>
          <div v-if="isNotification" class="notification z-50 rounded-lg absolute w-full">
            <div class="notification-title p-4 text-center text-xl text-white rounded-t font-semibold">
              {{ $t('notifications') }}
            </div>
            <div class="is-notifffsss overflow-y-scroll">
              <div
                v-if="numberNotification === 0"
                class="no-notification text-xl text-center italic my-48 text-gray-400"
              >
                {{ $t('noNotificationAdded') }}
              </div>
              <div
                v-for="(item, i) in allNotification"
                :key="i"
                class="notification-content p-4 border-b cursor-pointer"
                @click="markAsRead(i)"
              >
                <div v-if="item.type === 'treatedIncident'" class="conteneur">
                  <span>{{ 'Nouvel incident portant sur ' }} <i>{{ item.incident.typeIncident }}</i>{{', ajouté par ' }} <b>{{ item.incident.name }}</b>{{ ', a été traité avec succès par ' }}</span>
                  <span
                    v-for="(admin, adm) in allAdmins"
                    :key="adm"
                  >
                  <span v-if="admin.uid === item.addedBy">
                    <b>{{ admin.surname + ' ' + admin.name }}</b>.
                  </span>
                </span>
                </div>
                <div v-if="item.type === 'newRequest'" class="conteneur">
                  <div
                    v-for="(req, index) in allRequests"
                    :key="index"
                    class="request"
                  >
                  <span
                    v-if="req.uid === item.details"
                    class="is-request"
                  >
                    <span>
                      {{ 'Nouvelle requête portant sur ' }} <i>{{ req.object }}</i>{{ ', a été émise par ' }}
                      <span
                        v-for="(agent, agt) in allAgents"
                        :key="agt"
                      >
                        <span v-if="agent.uid === req.addedBy">
                          <b>{{ agent.name }}</b>
                        </span>
                      </span>
                    </span>
                  </span>
                  </div>
                </div>
                <div v-if="item.type === 'newCurrentHand'" class="conteneur">
                  <div
                    v-for="(req, index) in allCurrentHands"
                    :key="index"
                    class="request"
                  >
                  <span
                    v-if="req.uid === item.details"
                    class="is-request"
                  >
                    <span>
                      {{ 'Nouvelle main courante portant sur ' }}
                      <span
                        v-for="(event, evt) in allEvents"
                        :key="evt"
                      >
                        <span v-if="event.uid === req.event">
                          <i>{{ event.title }}</i>{{ ', a été déclarée par ' }}
                          <span
                            v-for="(agent, agt) in allAgents"
                            :key="agt"
                          >
                            <span v-if="agent.uid === req.addedBy">
                              <b>{{ agent.name }}</b>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part-2 flex items-center">
        <div v-if="showAgentFilter" class="file-button mr-3 w-48">
          <Multiselect
            v-model="agentFunction"
            :options="optionsAgentFunction"
            label="name"
            valueProp="value"
            placeholder="Fonction agent"
            :classes="{
                    container: 'ipt relative mx-auto bg-white w-full flex items-center justify-end box-border cursor-pointer border rounded-lg p-4 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
            @select="sendAgentFunction"
          />
        </div>
        <div v-if="showFileButton" class="file-button mr-3">
          <button-base
            :label="fileButton"
            :show-icon="true"
            :name-icon="icons.excel"
            background="#31B049"
            @click="clickGreenButton"
          />
        </div>
        <div v-if="showAddButton" class="add-button ml-3">
          <button-base
            :label="addButton"
            @click="clickBlueButton"
          />
        </div>
        <div v-if="showZoneButton" class="add-button ml-6">
          <button-base
            :label="zoneButton"
            @click="clickZoneButton"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searching from '../assets/icons/ic_search.svg'
import notifications from '../assets/icons/ic_notif.svg'
import notification from '../assets/icons/ic_notifications.svg'
import ButtonBase from './helper/add/button-base'
import excel from '../assets/icons/ic_excel.svg'
import { collection, getDocs, orderBy, query, where, limit, doc, updateDoc, onSnapshot } from 'firebase/firestore'
import { firestore } from '../main'
import Multiselect from '@vueform/multiselect'
export default {
  name: 'the-header',
  components: { ButtonBase, Multiselect },
  props: {
    showAddButton: {
      type: Boolean,
      default: true
    },
    addButton: {
      type: String,
      default: 'Ajouter un site'
    },
    showFileButton: {
      type: Boolean,
      default: true
    },
    fileButton: {
      type: String,
      default: 'Ajouter un document'
    },
    showZoneButton: {
      type: Boolean,
      default: false
    },
    zoneButton: {
      type: String,
      default: 'Ajouter une zone'
    },
    showNumber: {
      type: Boolean,
      default: true
    },
    numbers: {
      type: Number,
      default: 212
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    showAgentFilter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons: { notification, searching, notifications, excel },
      currentRoute: '',
      allNotification: [],
      isNotification: false,
      search: '',
      agentFunction: '',
      optionsAgentFunction: [
        { name: this.$t('agent'), value: 'agent' },
        { name: this.$t('supervisors'), value: 'supervisor' },
        { name: this.$t('siteManager'), value: 'manager' }
      ],
      allRequests: [],
      allAgents: [],
      allCurrentHands: [],
      allEvents: [],
      allAdmins: []
    }
  },
  created () {
    this.currentRouteName()
    this.getAllNotifications()
    this.getAllRequests()
    this.getAllCurrentHands()
    this.getAllAgents()
    this.getAllEvents()
    this.getAllAdmins()
  },
  computed: {
    numberNotification: function () {
      const tab = []
      for (let i = 0; i < this.allNotification.length; i++) {
        if (this.allNotification[i].isRead === false) {
          tab.push(this.allNotification[i])
        }
      }
      return tab.length
    }
  },
  methods: {
    sendAgentFunction () { // SELECT AGENT FUNCTION FOR FILTER
      this.$emit('sendAgentFunction', this.agentFunction)
    },

    async markAsRead (index) { // MARK ONE NOTIFICATION AS READ
      const washingtonRef = doc(firestore, 'adminsNotifications', this.$store.getters.userId, 'notifications', this.allNotification[index].uid)
      await updateDoc(washingtonRef, {
        isRead: true
      }).then(response => {
        console.log(response)
        // this.allNotification[index].isRead = true
        if (this.allNotification[index].type === 'newRequest') {
          this.$router.push({ path: '/requests' })
        } else if (this.allNotification[index].type === 'newCurrentHand') {
          this.$router.push({ path: '/agents' })
        } else if (this.allNotification[index].type === 'treatedIncident') {
          this.$router.push({ path: '/incidents' })
        }
        this.allNotification.splice(index, 1)
        this.isNotification = false
        this.numberNotification = this.numberNotification - 1
      }).catch(error => {
        console.log(error)
        console.log('erreur')
      })
    },

    async getAllNotifications () { // GET ALL ADMINS INFORMATION
      const q = query(collection(firestore, 'adminsNotifications', this.$store.getters.userId, 'notifications'),
        where('isRead', '==', false),
        orderBy('createdAt', 'desc'),
        limit(100))
      onSnapshot(q, (querySnapshot) => {
        const cities = []
        querySnapshot.forEach((doc) => {
          // console.log(doc.data())
          cities.push(doc.data())
          this.allNotification = cities
        })
      })
    },

    async getAllRequests () { // GET ALL REQUESTS INFORMATION
      const q = query(collection(firestore, 'requests'),
        orderBy('createdAt', 'desc')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allRequests.push(doc.data())
      })
    },

    async getAllCurrentHands () { // GET ALL COURANT HANDS INFORMATION
      const q = query(collection(firestore, 'courant_hands'),
        orderBy('createdAt', 'desc')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allCurrentHands.push(doc.data())
      })
    },

    async getAllAgents () { // GET ALL AGENTS INFORMATION
      const q = query(collection(firestore, 'agents'),
        orderBy('createdAt', 'desc')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allAgents.push(doc.data())
      })
    },

    async getAllEvents () { // GET ALL EVENTS INFORMATION
      const q = query(collection(firestore, 'event'),
        orderBy('createdAt', 'desc')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allEvents.push(doc.data())
      })
    },

    async getAllAdmins () { // GET ALL ADMINS INFORMATION
      const q = query(collection(firestore, 'admins'),
        orderBy('createdAt', 'desc')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allAdmins.push(doc.data())
      })
    },

    searchForItems () {
      this.$emit('searchForItems', this.search)
    },

    openNotification () {
      this.isNotification = !this.isNotification
    },

    clickGreenButton () {
      this.$emit('clickGreenButton', true)
    },

    clickBlueButton () {
      this.$emit('clickBlueButton', true)
    },

    clickZoneButton () {
      this.$emit('clickZoneButton', true)
    },

    currentRouteName () {
      if (this.$route.name.toLowerCase() === 'dashboard') {
        this.currentRoute = this.$t('dashboardMenu')
      } else if (this.$route.name.toLowerCase() === 'incidents') {
        this.currentRoute = this.$t('incidentsMenu')
      } else if (this.$route.name.toLowerCase() === 'sites') {
        this.currentRoute = this.$t('sitesMenu')
      } else if (this.$route.name.toLowerCase() === 'agents') {
        this.currentRoute = this.$t('agentsMenu')
      } else if (this.$route.name.toLowerCase() === 'requests') {
        this.currentRoute = this.$t('requestsMenu')
      } else if (this.$route.name.toLowerCase() === 'presence') {
        this.currentRoute = this.$t('presenceMenu')
      } else if (this.$route.name.toLowerCase() === 'equipments') {
        this.currentRoute = this.$t('equipmentsMenu')
      } else if (this.$route.name.toLowerCase() === 'instructions') {
        this.currentRoute = this.$t('instructionsMenu')
      } else if (this.$route.name.toLowerCase() === 'personal') {
        this.currentRoute = this.$t('agentsPersonalMenu')
      } else if (this.$route.name.toLowerCase() === 'administrators') {
        this.currentRoute = this.$t('administratorsMenu')
      }
      return this.currentRoute
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .title {
    color: $team_gray_title;
  }
  .svg-fill {
    fill: transparent;
  }
  .file-button::v-deep, .add-button::v-deep {
    .button-base {
      .page-button-real {
        border-radius: .5rem;
      }
    }
  }
  .notification {
    display: block;
    position: absolute;
    height: auto;
    min-height: 400px;
    width: 30rem;
    left: -10rem;
    background-color: white;
    top: 40px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .322);
    z-index: 50;

  }
  .is-notifffsss {
    max-height: 400px;
  }
  .notification-title {
    background: $team_blue;
  }
  .notification-content {
    background: rgba(178, 139, 103, .15);
  }
</style>
